import moment from 'moment';
import {
    generateActivityTemporalNeedSliceFromActivityCalendarSlice,
} from '../../helpers/slicing-helper';
import { dateToTimestamp } from '../../helpers/planning';
import { xAxisDateFormat } from '../../components/charts/CustomHistogramDateXaxis';
import { selectActivityEntities } from '../slices/app/activity.slice';
import { selectPlanningSelected } from '../slices/app/planning.slice';
import { selectActivityCalendarSlice, selectScale } from '../slices/slicing/activity-calendar.slice';
import { selectTimeUnits } from '../slices/app/calendar.slice';
import createAppAsyncThunk from './create-typed-async-thunk';
import {
    DEFAULT_CHART_OPTIONS,
    selectChartOptions,
    setChartData,
    setChartOptions,
    setNeedList,
    setShouldUpdateChartData,
} from '../slices/chart/need-chart.slice';
import { selectTabPreferences, updateTabPreferences } from '../slices/app/tab.slice';
import { getArrayInListByCondition } from '../../helpers/tree-helper';
import { NEED_TYPE } from '../../constants/Needs';
import { translateWithPrefix } from '../../i18n';
/* eslint-disable-next-line */
import Worker from '../../workers/chart-data.worker';

/**
 * Store the last data sent to the worker to prevent unnecessary worker creation
 * This is kept outside the thunk to persist between function calls
 */
let lastWorkerData = null;

/**
 * Reference to the current active worker
 * This allows us to terminate it if a new request comes in
 */
let currentWorker = null;

/**
 * Keep track of the latest request data
 * This ensures we can always access the most recent request data
 */
let latestRequestData = null;

/**
 * Computes temporal need slices from activity calendar slices
 * This is an intermediary data structure used for chart calculations
 */
export const computeActivityCalendarTemporalSliceDictionary = createAppAsyncThunk(
    'chart/computeActivityCalendarTemporalSliceDictionary',
    async (_, { getState }) => {
        const generalTranslation = translateWithPrefix('general');
        const state = getState();
        const scale = selectScale(state);
        const activityCalendarSliceDictionary = selectActivityCalendarSlice(state);
        const { chartOptions } = state.needChart;
        const activitiesDictionary = selectActivityEntities(state);
        const timeUnits = selectTimeUnits(state);

        // Create an empty dictionary to store the generated temporal need slices
        const activityCalendarTemporalNeedSlices = {};
        
        // Get the temporal unit based on the current scale
        const temporalityUnit = xAxisDateFormat(generalTranslation)[scale].mainScaleUnit;
        
        // Generate temporal need slices for each activity
        Object.values(activitiesDictionary).forEach((activity: any) => {
            // Only process activities that have calendar slices
            if (activityCalendarSliceDictionary[activity.id]?.length > 0) {
                const currentActivityCalendarTemporalNeedSlices =
                    generateActivityTemporalNeedSliceFromActivityCalendarSlice(
                        activityCalendarSliceDictionary[activity.id],
                        temporalityUnit,
                        activity,
                        (dayDefinitionId) => timeUnits.find((timeUnit) => timeUnit.id === Number(dayDefinitionId)),
                        chartOptions
                    );

                activityCalendarTemporalNeedSlices[activity.id] = currentActivityCalendarTemporalNeedSlices;
            }
        });

        return activityCalendarTemporalNeedSlices;
    }
);

/**
 * Helper function to start a new worker with the provided data
 * This centralizes worker creation logic to avoid code duplication
 */
function startWorker(dispatch, workerData) {
    // Performance measurement
    const timeStart = Date.now();
    
    // Create a new web worker
    const worker = new Worker();
    
    // Store reference to the current worker
    currentWorker = worker;
    
    // Set up worker message handler to process the result
    worker.onmessage = (event) => {
      // Only update the chart data if this is still the current worker
      if (worker === currentWorker) {
        // Update the chart data in the Redux store
        dispatch(setChartData(event.data));
        console.log('Chart data processing finished after', (Date.now() - timeStart)/1000, 's');
        currentWorker = null;
      } else {
        console.log('Ignoring results from obsolete worker');
      }
      worker.terminate(); // Terminate the worker after receiving the result
    };
    
    // Set up worker error handler
    worker.onerror = (error) => {
      console.error('Worker error:', error);
      if (worker === currentWorker) {
        currentWorker = null;
        
        // In case of error, make sure we preserve the latest request data
        // so it can be processed again if needed
        lastWorkerData = latestRequestData;
      }
      worker.terminate(); // Terminate the worker in case of an error
    };
    
    // Send the data to the worker for processing
    worker.postMessage({
      type: 'COMPUTE_CHART_DATA',
      ...workerData
    });
  }

/**
 * Main thunk to compute chart data using a web worker
 * Only creates and sends data to a worker when the input data has changed
 */
export const computeChartData = createAppAsyncThunk(
  'chart/computeChartData',
  async (_, { getState, dispatch }) => {
    const state = getState();
    const {
      activityCalendarTemporalNeedSliceDictionary,
      needList,
      chartOptions
    } = state.needChart;

    const {processingGantt} = state.planning;
    if (processingGantt) {
      return 'not ready';
    }
    
    const scale = selectScale(state);
    const planningSelected = selectPlanningSelected(state);
    const activitiesDictionary = selectActivityEntities(state);
    const generalTranslation = translateWithPrefix('general');

    // Determine the time unit based on the current scale
    const timeUnit = xAxisDateFormat(generalTranslation)[scale].mainScaleUnit;
    
    // Get the root activity for time range calculations
    const rootActivity = activitiesDictionary[planningSelected.rootActivityId];
    
    // Calculate startTime based on gantt instance if available, otherwise use root activity start date
    const startTime = (window as any).ganttInstance
        ? dateToTimestamp((window as any).ganttInstance.getState().min_date)
        : rootActivity.startDate;
    
    // Calculate endTime based on gantt instance if available, otherwise use root activity end date + buffer
    const endTime = (window as any).ganttInstance
        ? dateToTimestamp(
              (window as any).ganttInstance.date.add(
                  (window as any).ganttInstance.getState().max_date,
                  0,
                  timeUnit.replace('s', '')
              )
          )
        : moment(rootActivity.endDate).utc().add(2, timeUnit).valueOf();
    
    // Gather activities data from gantt or fallback to all activities
    const ganttData = (window as any).ganttInstance 
        ? (window as any).ganttInstance.getTaskByTime().map((act) => act.data_api) 
        : Object.values(activitiesDictionary);

    // Prepare the current data to be sent to the worker
    const currentWorkerData = {
      activityCalendarTemporalNeedSliceDictionary,
      needList,
      chartOptions,
      startTime,
      endTime,
      ganttData,
      timeUnit
    };

    // Save the latest request data
    latestRequestData = { ...currentWorkerData };
    
    // Create a serializable version of the data for comparison
    // We need to stringify and then parse to create a deep copy for comparison
    const serializedCurrentData = JSON.stringify(currentWorkerData);
    const serializedLastData = JSON.stringify(lastWorkerData);

    // Abort any existing worker but ensure we don't lose data
    if (currentWorker) {
      console.log('Aborting previous chart data computation, starting new calculation');
      currentWorker.terminate();
      currentWorker = null;
      
      // Update the last sent data reference even when aborting
      // This prevents data loss by ensuring we track the new request
      lastWorkerData = JSON.parse(serializedCurrentData);
      
      // Immediately start processing the new request without checking lastWorkerData
      // This guarantees we'll always process the latest request
      startWorker(dispatch, currentWorkerData);
      return 'computeChartData restarted with new data';
    }
    
    // Only create a worker and send data if it has changed
    if (serializedCurrentData !== serializedLastData) {
      console.log('Chart data changed, creating new worker');
      
      // Update the last sent data reference
      lastWorkerData = JSON.parse(serializedCurrentData);
      
      // Start a new worker with the updated data
      startWorker(dispatch, currentWorkerData);
    } else {
      console.log('Chart data unchanged, skipping worker creation');
    }
    
    return 'computeChartData done';
  }
);



/**
 * Updates chart options and triggers recalculation if necessary
 * @param newOptions - Partial options to update
 */
export const updateChartOptions = createAppAsyncThunk(
    'chart/updateChartOptions',
    async (newOptions: Partial<typeof DEFAULT_CHART_OPTIONS>, { dispatch, getState }) => {
        const state = getState();
        const currentOptions = state.needChart.chartOptions;
        
        // Merge new options with current options
        const updatedOptions = { ...currentOptions, ...newOptions };

        // Update options in the Redux store
        dispatch(setChartOptions(updatedOptions));
        
        // Save options in tab preferences for persistence
        dispatch(
            updateTabPreferences({
                gantt_histogram_options: updatedOptions,
            })
        );

        // Only trigger chart data recalculation if critical options changed
        if (newOptions.needType || newOptions.dataType || newOptions.sliceDataType) {
            dispatch(computeChartData());
        }
    }
);

/**
 * Updates the list of needs based on the current need type selection
 * Filters needs from global and planning lists
 */
export const updateNeedList = createAppAsyncThunk(
    'chart/updateNeedList', 
    async (_, { dispatch, getState }) => {
        const state = getState();
        const chartOptions = selectChartOptions(state);
        const { globalNeedList, planningNeedList } = state.needs;
        const tabPreferences = selectTabPreferences(state);
        
        // Flag that chart data needs to be updated
        dispatch(setShouldUpdateChartData(true));
        
        // Filter needs based on the selected need type
        const needs = (
            chartOptions.needType === NEED_TYPE.NON_CONSUMMABLE
                ? [
                      ...getArrayInListByCondition(globalNeedList, 'needType', NEED_TYPE.NON_CONSUMMABLE),
                      ...getArrayInListByCondition(planningNeedList, 'needType', NEED_TYPE.NON_CONSUMMABLE),
                  ]
                : [
                      ...getArrayInListByCondition(globalNeedList, 'needType', NEED_TYPE.CONSUMMABLE),
                      ...getArrayInListByCondition(planningNeedList, 'needType', NEED_TYPE.CONSUMMABLE),
                  ]
        ).map((need) => ({
            ...need,
            // Set the selected state based on saved preferences or default to true
            selected: tabPreferences.gantt_histogram_needs?.[chartOptions?.needType]
                ? tabPreferences.gantt_histogram_needs[chartOptions?.needType].indexOf(need.id) !== -1
                : true,
        }));

        // Update the need list in the Redux store
        dispatch(setNeedList(needs));
    }
);

export default computeChartData;
