import { sortBy } from 'lodash';
import i18n from '../../i18n';
import customFieldService from '../../services/custom-field.service';
import folderService from '../../services/folder.service';
import userService from '../../services/user.service';
import Planning from '../models/planning.model';
import { setNotificationError, setRequestError } from '../slices/app/error.slice';
import {
    resetPlanningData,
    selectPlanningSelected,
    setAllCustomFields,
    setLoadingGantt,
    setPlanningSelected,
    setSpinProgress,
    setJobs,
    selectModeSelected,
    incrementRefreshGantt,
} from '../slices/app/planning.slice';
import { selectActiveTabFilterData } from '../slices/app/tab.slice';
import { selectCurrentUser, selectUserPreferences, updateUserPreferences } from '../slices/app/user.slice';
import { buildRequestErrorObject } from '../utils/ErrorSliceUtils';
import { requestPlanningsInFolderForMode, canAccessToPlanning } from '../utils/PlanningSliceUtils';
import createAppAsyncThunk from './create-typed-async-thunk';
import { selectTimeUnits } from '../slices/app/calendar.slice';
import { removeAllActivities } from '../slices/app/activity.slice';
import fetchCalendars from './calendar-slice.thunk';
import { fetchActivities } from './activity-slice.thunk';
import activityService from '../../services/activity.service';
import { saveScrollState } from '../../components/plannings/gantt_events';
import { deleteLogs } from '../../logger';
import { fetchLinks } from './link-slice.thunk';
import { getPlanningAccess, USER_ACCESS } from '../../helpers/planning';
import { notificationError } from '../../helpers/notification';

const { t } = i18n;

export const loadUserJobs = createAppAsyncThunk(
    'user/loadUserJobs',
    async (planning: Planning, { rejectWithValue, dispatch }) => {
        try {
            const jobs = await activityService.listUserJobs(planning.rootActivityId);
            dispatch(setJobs(jobs.map((job) => job.value)));
            return jobs;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const getPlanningCustomFields = createAppAsyncThunk(
    'planning/updateCustomFields',
    async (payload, { getState, rejectWithValue, dispatch }) => {
        const state = getState();
        const currentPlanning = selectPlanningSelected(state);

        if (!currentPlanning) {
            return rejectWithValue('No planning selected');
        }

        try {
            const customFieldsOfPlanning = await customFieldService.planningCustomFields(currentPlanning.id);
            const defaultCustomFields = await customFieldService.defaultsCustomFields();

            const allCustomFields = sortBy(
                [
                    ...defaultCustomFields.map((field) => ({
                        ...field,
                        name: t(`default_fields.${field.name}`),
                    })),
                    ...customFieldsOfPlanning,
                ],
                ['name']
            );

            dispatch(setAllCustomFields(allCustomFields));
        } catch (error) {
            dispatch(setRequestError(buildRequestErrorObject(t('planning_context.error_get_planning_fields'), error)));
        }
        return 'All planning fields got';
    }
);

export const getPlanningData = createAppAsyncThunk(
    'planning/getPlanningData',
    async (payload, { getState, dispatch }) => {
        const state = getState();
        const planningSelected = selectPlanningSelected(state);
        const timeUnits = selectTimeUnits(state);
        const tabFilterData = selectActiveTabFilterData(state);
        if (planningSelected && timeUnits.length) {
            dispatch(setLoadingGantt(true));
            await dispatch(getPlanningCustomFields());
            await dispatch(fetchActivities());
            dispatch(fetchLinks());
            dispatch(setSpinProgress(30));
            const isFiltered = tabFilterData?.dateFilter;
            if (isFiltered) {
                const fin = isFiltered.rules[0];
                const debut = isFiltered.rules[1];
                await dispatch(fetchCalendars({ debut: +debut?.value, fin: +fin?.value }));
            } else {
                await dispatch(fetchCalendars({}));
            }
            dispatch(setLoadingGantt(false));
            // });
        }
        return true;
    }
);
export const updatePlanning = createAppAsyncThunk(
    'planning/updatePlanning',
    async ({ planning }: any, thunkApi) => {
        deleteLogs();
        if (planning) {
        const state = thunkApi.getState();
        const userPreferences = selectUserPreferences(state);
        const { modeSelected } = state.planning;
        const isPlanningChanged = userPreferences.open_planning?.[modeSelected]?.id !== planning.id;
        const isSummaryIdChanged = !isPlanningChanged && userPreferences.open_planning?.[modeSelected]?.summaryId !== planning.summaryId;
        console.log('isPlanningChanged', isPlanningChanged);
        console.log('isSummaryIdChanged', isSummaryIdChanged);
        try {
                const currentUser = selectCurrentUser(thunkApi.getState());
                if (planning.folderId && planning.folderId !== userPreferences.open_folder.id) {
                    notificationError(t('planning_context.error_planning_folder_changed'), '', {duration: 0});
                }
                thunkApi.dispatch(setLoadingGantt(true));
                if (getPlanningAccess(planning, currentUser) === USER_ACCESS.NONE) {
                    await thunkApi.dispatch(updateUserPreferences({ open_planning: { ...userPreferences.open_planning, [modeSelected]: planning }}));
                    thunkApi.dispatch(setLoadingGantt(false));
                    return await thunkApi.dispatch(setPlanningSelected(null));
                }
                if (isPlanningChanged) {
                    thunkApi.dispatch(removeAllActivities());
                    thunkApi.dispatch(resetPlanningData());
                }
                console.log('isPlanningChanged', isPlanningChanged);
                console.log('isSummaryIdChanged', isSummaryIdChanged);
                if (
                    Object.keys(userPreferences).length === 0 ||
                    !userPreferences.open_planning ||
                    isPlanningChanged ||
                    isSummaryIdChanged
                ) {
                    const updateObject = {
                        open_planning: { ...userPreferences.open_planning, [modeSelected]: planning },
                    };
                    await thunkApi.dispatch(updateUserPreferences(updateObject));
                    if (isSummaryIdChanged ) {
                        thunkApi.dispatch(incrementRefreshGantt());
                    }
                    // await updatePlanningCustomFields(newPlanning);
                }
                thunkApi.dispatch(loadUserJobs(planning));
                if (planning.folderId) {
                    userService.updateCurrentPlanning(planning.id, planning.name);
                }
                thunkApi.dispatch(setLoadingGantt(false));
            } catch (error) {
                thunkApi.dispatch(setRequestError({ error, title: t('planning_context.error_load_planning') }));
                thunkApi.dispatch(setLoadingGantt(false));
            }
            await thunkApi.dispatch(setPlanningSelected({ ...planning }));
            if (isSummaryIdChanged) {
                thunkApi.dispatch(incrementRefreshGantt());
            }
            return true;
        }
        return false;
    }
);

export const getPlanning = createAppAsyncThunk('planning/getPlanning', async (payload, thunkApi) => {
    const state = thunkApi.getState();
    const userPreferences = selectUserPreferences(state);
    const currentUser = selectCurrentUser(state);
    const currentFolderId = state.folder.folderSelected.id;
    const openPlanning: Planning = userPreferences.open_planning[state.planning.modeSelected];
    if (openPlanning) {
        thunkApi.dispatch(setSpinProgress(20));
        try {
            const planningData = await folderService.showPlanning(openPlanning.id);
            if (canAccessToPlanning(planningData, currentUser) && currentFolderId === openPlanning.folderId) {
                thunkApi.dispatch(updatePlanning({ planning: {...planningData, summaryId: openPlanning.summaryId} }));
            } else {
                throw new Error(t('planning_context.no_access_planning'));
            }
        } catch (e) {
            console.log('���� ~ file: PlanningContext.jsx:784 ~ onModeChange ~ e:', e);
            requestPlanningsInFolderForMode(currentFolderId, userPreferences.open_mode)
                .then((currentFolderPlannings) => {
                    const planningWithAccess = currentFolderPlannings.filter((p) =>
                        canAccessToPlanning(p, currentUser)
                    );
                    if (planningWithAccess.length) {
                        thunkApi.dispatch(updatePlanning({ planning: planningWithAccess.first() }));
                    } else if (userPreferences.open_mode !== 'sandbox') {
                        thunkApi.dispatch(
                            setNotificationError({
                                title: t('planning_context.no_access_folder_planning'),
                                description: '',
                            })
                        );
                    }
                })
                .catch((error) => {
                    thunkApi.dispatch(setRequestError({ title: t('planning_context.error_get_plannings'), error }));
                });
        }
    } else {
        thunkApi.dispatch(setPlanningSelected(null));
    }

});

export const refreshPlanningSelected = createAppAsyncThunk(
    'planning/refreshPlanningSelected',
    async (refetchActivity: boolean = false, { dispatch, getState }) => {
        const planningSelected = selectPlanningSelected(getState());
        const modeSelected = selectModeSelected(getState());
        await saveScrollState();
        dispatch(setLoadingGantt(true));
        dispatch(resetPlanningData());

        const planningPromise =
            modeSelected === 'sandbox'
                ? folderService.showSandboxPlanning(planningSelected.id)
                : folderService.showPlanning(planningSelected.id);

        const planningUpdated = await planningPromise;

        await dispatch(setPlanningSelected({...planningUpdated, summaryId: planningSelected.summaryId}));

        if (refetchActivity) {
            dispatch(fetchActivities());
        }

        (window as any).disableBroadcastUpdate = false;

        dispatch(setLoadingGantt(false));
        return planningUpdated;
    }
);


export const refreshPlanningCustomFields = (action, object) => (dispatch, getState) => {
    const state = getState();
    const oldCustomFields = [...state.planning.allCustomFields];

    switch (action) {
        case 'new':
            oldCustomFields.push(object);
            break;
        case 'edit':
        case 'broadcast': {
            const indexFilter = oldCustomFields.findIndex((i) => i.id === object.id);
            if (indexFilter > -1) {
                oldCustomFields[indexFilter] = object;
            } else if (action === 'broadcast') {
                oldCustomFields.push(object);
            }
            break;
        }
        case 'delete': {
            const indexFilter = oldCustomFields.findIndex((i) => i.id === object.id);
            if (indexFilter > -1) {
                oldCustomFields.splice(indexFilter, 1);
            }
            break;
        }
        default:
            break;
    }

    // update user jobs
    if (object.name === 'JOB_FIELD') {
        dispatch(loadUserJobs(state.planning.planningSelected));
    }

    dispatch(setAllCustomFields(oldCustomFields));
};

export default getPlanning;
